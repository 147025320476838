import { createRouter, createWebHistory } from '@ionic/vue-router';
import HomePage from '../views/HomePage.vue';
import Incoming from '../views/IncomingItems.vue';
import Login from "../views/LogIn.vue";
import Movement from '../views/MoveItems.vue';
import Outgoing from '../views/OutgoingItems.vue';
import Root from "../views/RootActions.vue";
import SelectOrder from '../views/SelectOrder.vue';
import ManageOrder from '../views/ManageOrder.vue';
import SelectInventory from '../views/SelectInventory.vue';
import ManageListInventory from '../views/ManageListInventory.vue';
import ManageFreeInventory from '../views/ManageFreeInventory.vue';
import SelectProject from '../views/SelectProject.vue'; // '../views/SelectOrder.vue'
import ManageProject from '../views/ManageProject.vue'; // '../views/ManageOrder.vue'
import SelectProjectForPackages from '../views/SelectProjectForPackages.vue';
import ManagePackages from '../views/ManagePackages.vue';
import SelectPickingLists from '../views/SelectPickingLists.vue'; // '../views/SelectOrder.vue'
import ManagePickingLists from '../views/ManagePickingLists.vue'; // '../views/ManageOrder.vue'

const routes = [
	{
		path: "/",
		name: "Root",
		component: Root,
	},
	{
		path: "/login",
		name: "LogIn",
		component: Login,
	},
	{
		path: '/home',
		name: 'Home',
		component: HomePage
	},
	{
		path: '/order/selection',
		name: 'SelectOrder',
		component: SelectOrder
	},
	{
		path: '/order/management/:id',
		name: 'ManageOrderId',
		component: ManageOrder
	},
	{
		path: '/picking/selection',
		name: 'SelectPickingLists',
		component: SelectPickingLists
	},
	{
		path: '/picking/management',
		name: 'ManagePickingLists',
		component: ManagePickingLists
	},
	{
		path: '/project/selection',
		name: 'SelectProject',
		component: SelectProject
	},
	{
		path: '/project/management/:id',
		name: 'ManageProjectId',
		component: ManageProject
	},
	{
		path: '/package/selection',
		name: 'SelectProjectForPackages',
		component: SelectProjectForPackages
	},
	{
		path: '/package/management/:id',
		name: 'ManagePackages',
		component: ManagePackages
	},
	{
		path: '/incoming',
		name: 'IncomingItems',
		component: Incoming
	},
	{
		path: '/incoming/:id',
		name: 'IncomingItemsId',
		component: Incoming,
	},
	{
		path: '/outgoing',
		name: 'OutgoingItems',
		component: Outgoing
	},
	{
		path: '/outgoing/:id',
		name: 'OutgoingItemsId',
		component: Outgoing
	},
	{
		path: '/inventory/selection',
		name: 'SelectInventory',
		component: SelectInventory
	},
	{
		path: '/inventory/list_management/:id',
		name: 'ManageListInventory',
		component: ManageListInventory
	},
	{
		path: '/inventory/free_management/:id',
		name: 'ManageFreeInventory',
		component: ManageFreeInventory
	},
	{
		path: '/movement',
		name: 'MoveItems',
		component: Movement
	},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
